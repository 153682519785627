<template>
    <!-- 这个是拣货 -->
    <el-drawer
        size="90%"
        :visible.sync="drawerVisiable"
        :show-close="false"
        @close="close"
        append-to-body
        :wrapperClosable="false"
        :close-on-press-escape="false"
        custom-class="check-accept-drawer"
    >
        <div slot="title" class="drawer-top row align-center space-between">
            <div class="drawer-top-left">
                <h3 class="label">拣货</h3>
                <div class="title-item">
                    <div class="title-label">收货方</div>
                    <div class="title-value">{{ cardObj.receiverName }}</div>
                </div>
                <div class="title-item">
                    <div class="title-label">单据号</div>
                    <div class="title-value">{{ cardObj.invoicesSn }}</div>
                </div>
            </div>
            <div class="drawer-top-right" style="display: flex; align-items: center">
                <basic-button @click="beforeSubmit" type="primary" icon-class="save" :loading="loadingSubmit"  style="width: 200px">
                    提交并完成
                </basic-button>
                <i
                    class="el-icon-close"
                    style="font-size: 20px; color: #000; font-weight: bold; margin-left: 20px; cursor: pointer"
                    @click="close"
                ></i>
            </div>
        </div>
        <div v-loading="loading">
            <div class="search-row">
                <div class="search-card">
                    <div class="search">
                        <i
                            class="iconfont icon-saoma"
                            style="font-size: 24px"
                            :style="{ color: isInput ? '#C56209' : '#067CF2' }"
                        ></i>
                        <div class="search-line" :style="{ background: isInput ? '#C56209' : '#067CF2' }"></div>
                        <input
                            type="text"
                            ref="searchCode"
                            placeholder="请扫码或输入条码"
                            @focus="isInput = true"
                            @blur="isInput = false"
                            v-focusInput="1"
                            @keyup.enter="searchCode($event)"
                            v-model="barCode"
                        />
                        <div class="search-btn" @click="searchCode()">确定</div>
                    </div>
                </div>

                <el-switch
                    size="small"
                    v-model="scanFlag"
                    inactive-color="#44DB5E"
                    active-color="#ff0000"
                    active-text="负扫"
                    @change='control'
                    inactive-text="正扫"
                    style="margin: 0 40px"
                ></el-switch>
                <el-input-number size="small" :min="1" v-model="scanNum" :precision="0" style="margin: 0 40px" @change='control'></el-input-number>
                <div style="margin: 0 40px">
                    <el-checkbox v-model="checked" border  @change='control'>只显示未拣</el-checkbox>
                </div>
                <div style="display: flex">
                    <el-input placeholder="请输入货号" v-model="joinGoods" clearable @clear="searchJoin"></el-input>
                </div>
                <basic-button native-type="submit" type="primary" @click="searchJoin" style="margin-left: 20px">
                    查询
                </basic-button>
                <basic-button @click="clickScantraing" type="primary"> 扫码轨迹 </basic-button>
                <basic-button @click="clearGoods" type="primary"> 清空拣货数量 </basic-button>

            </div>
            <div style="margin: 20px">
                <el-table
                    ref="dataOptions"
                    :data="[dataOptions[0]]"
                    tooltip-effect="dark"
                    class="tablues"
                    border
                    :header-cell-style="{
                        background: '#e8e8e8',
                        padding: '5px 0',
                        color: '#333',
                        fontSize: '14px',
                        height: '40px'
                    }"
                    :cell-style="{
                        padding: '0px 0',
                        fontSize: '14px',
                        height: '30px'
                    }"
                >
                    <el-table-column prop="goodsName" label="当前扫码货品" align="center"> </el-table-column>
                    <el-table-column prop="goodsNo" label="货号" align="center"> </el-table-column>
                    <el-table-column prop="color" label="颜色" align="center"> </el-table-column>
                    <el-table-column prop="size" label="尺码" align="center"></el-table-column>
                    <el-table-column prop="newNum" label="数量" align="center"></el-table-column>
                </el-table>
            </div>
            <div style="margin: 20px">
                <SizeGroupTable
                    ref="sizeGroupTableIns"
                    :dataList="newDataList"
                    :updataList="updataList"
                    :updataIdx.sync="updataIdx"
                    :columns="addColumns"
                    :sizeInfoLabels="addSizeInfoLabel"
                    :filterText="filterText"
                    :footerOptions="footerList"
                    :isShowRow="checked"
                    border
                    @checkNumberChange="checkNumberChange"
                >
                    <template v-slot="{ row, item }">
                        <el-popover v-if="item.prop === 'goodsNo'" placement="right-start" trigger="click">
                            <el-image
                                v-if="row.imageList && row.imageList.length"
                                style="width: 400px; height: 400px"
                                :src="row.imageList[0]"
                                :preview-src-list="row.imageList"
                            >
                            </el-image>
                            <span slot="reference" class="text-link">{{ row.goodsNo }} </span>
                        </el-popover>
                    </template>
                </SizeGroupTable>

                <endPack
                    v-if="endPackDialog"
                    :modalParams="endPackParams"
                    type="2"
                    :documentType="snType"
                    @close="
                        (e, type) => {
                            endPackDialog = false
                            type ? submits(e) : ''
                        }
                    "
                ></endPack>
                <ScanTrajectory v-if="ScanTraDialog" :detailsList="dataOptions" @close="ScanTraclose"> </ScanTrajectory>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import endPack from './endPack.vue'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { addColumns, addSizeInfoLabel, footerList } from './tableConfig.js'
import {
  erp2ErpMarketReturnQueryPickDetail,
  erp2OperatingPick,
  erp2OperatingPickComplete,
  erp2rpMarketAllotQueryPickDetail,
  erp2OperatingCleanPickAll, erp2OperatingRestartPick
} from '@/libs/http/modules/erp.js'
import ScanTrajectory from '@/views/invoicesCenter/components/ScanTrajectory'

export default {
    components: {
        endPack,
        SizeGroupTable,
        ScanTrajectory
    },
    props: {
        modalParams: {
            type: Object,
            default: () => null
        },
        snType: {
            type: String,
            default: () => '' //3为店店调拨单 //2为店铺退仓单
        }
    },
    data() {
        return {
            addColumns,
            addSizeInfoLabel,
            footerList,
            drawerVisiable: false,
            ScanTraDialog: false,
            headerLeft: [
                { name: '货品名称', id: '1' },
                { name: '货号', id: '2' }
            ],
            cardObj: {},
            newDataList: [],
            updataList: [], //用来指定更新的数据
            sizeGroup: [],
            dataOptions: [], //当前扫码的货品列表
            barCode: '',
            joinGoods: '',
            checked: false,
            searchInNo: '',
            isInput: false,
            endPackDialog: false,
            endPackParams: {},
            scanNum: 1,
            scanFlag: false,
            filterText: '',
            loading: false,
            totalItem: [], //存放条码集合 判断是否会超拣货
            first: true, //判断是否是第一次进来
            updataIdx: '', //要更新的行
            isOverScan: false, //判断是否是超尺码拣货
            refetchBtn: false, //判断是否已经拣货了，用来自动刷新外面列表的开关
            loadingSubmit:false
        }
    },
    created() {
        this.$nextTick(() => {
            this.$refs.searchCode.focus()
        })
        this.drawerVisiable = true
        this.loading = true
        if (this.snType == '3') {
            this.dddBfetchDetails()
        } else {
            this.fetchDetails()
        }
    },
    methods: {
        control() {
            this.$nextTick(() => {
                this.$refs.searchCode.focus()
            })
        },
      clearGoods() {
        // erp2OperatingCleanPickAll
        this.$confirm('清空后货品需要重新拣货，确定清空?', '提示')
            .then(() => {
              let req = {
                typeCode: this.modalParams.typeCode,
                invoicesId: this.modalParams.id
              }
              erp2OperatingCleanPickAll(req)
                  .then((data) => {
                    this.loading=true
                    this.first=true
                    this.$message.success('操作成功')
                    if (this.snType == '3') {
                      this.dddBfetchDetails()
                    } else {
                      this.fetchDetails()
                    }
                    this.dataOptions=[]
                  })
                  .catch((error) => {
                    this.loading=false
                    this.$message.error(error)
                  })
            })
            .catch(() => {})
      },
        searchJoin() {
            this.filterText = this.joinGoods
        },
        clickScantraing() {
            this.ScanTraDialog = true
        },
        ScanTraclose() {
            this.ScanTraDialog = false
        },

        beforeSubmit() {
            let obj = this.$refs.sizeGroupTableIns.totalObj
            this.endPackParams = {
                ...obj,
                distriNumTotal: obj.checkGetNum,
                noReceiveNumTotal: obj.noReceiveNumTotal
            }
            if (
                this.endPackParams.noReceiveNumTotal == this.endPackParams.distriNumTotal &&
                this.endPackParams.excessReceiveNumTotal == 0
            ) {
                return this.$message({
                    type: 'error',
                    message: `请至少拣货一件`,
                    duration: 2000,
                    showClose: true
                })
            }
            if (this.endPackParams.noReceiveNumTotal > 0 || this.endPackParams.excessReceiveNumTotal > 0) {
                this.endPackDialog = true
                // if (this.cardObj.isOffset == 'N') {
                //     return this.$message({
                //         type: 'error',
                //         message: '不允许差异提交，请联系商品人员',
                //         duration: 2000,
                //         showClose: true
                //     })
                // } else {
                //     this.endPackDialog = true
                // }
            } else {
                this.submits()
            }
        },
        submits(e) {
            this.loadingSubmit=true
            let reqData = {
                invoicesId: this.cardObj.invoicesId,
                typeCode: this.cardObj.typeCode
            }
            if (e && this.snType == 3) {
                reqData['difReason'] = e
            }
            erp2OperatingPickComplete(reqData)
                .then((data) => {
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                    this.loadingSubmit=false
                    this.drawerVisiable = false
                    this.$emit('close', true)
                })
                .catch((err) => {
                    this.loadingSubmit=false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        dddBfetchDetails() {
            this.loading=true
            erp2rpMarketAllotQueryPickDetail({
                id: this.modalParams.id
            })
                .then((data) => {
                    this.loading = false
                    this.$nextTick(() => {
                        let list =
                            data.goodsInfoList.map((item) => {
                                item.sizeInfoList.forEach((i) => {
                                    i.num = i.num || 0
                                    i.receiveNum = i.receiveNum || 0
                                    i.noReceiveNum = i.noReceiveNum || 0
                                })
                                return item
                            }) || []
                        if (this.first || this.isOverScan) {
                            this.newDataList = list
                            this.first = false
                            this.isOverScan = false
                        }
                        this.totalItem = []
                        for (let i = 0; i < data?.goodsInfoList?.length; i++) {
                            // this.totalItem += data.goodsInfoList[i].sizeInfoList.length
                            for (let j = 0; j < data?.goodsInfoList[i]?.sizeInfoList.length; j++) {
                                this.totalItem.push(data?.goodsInfoList[i]?.sizeInfoList[j].barCode)
                            }
                        }

                        this.sizeGroup = data.sizeGroup
                        this.updataList = list
                    })
                    this.cardObj = data || {}
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                })
        },
        fetchDetails() {
            this.loading = true
            erp2ErpMarketReturnQueryPickDetail({
                id: this.modalParams.id
            })
                .then((data) => {
                    this.loading = false
                    let list =
                        data.goodsInfoList.map((item) => {
                            item.sizeInfoList.forEach((i) => {
                                i.num = i.num || 0
                                i.receiveNum = i.receiveNum || 0
                                i.noReceiveNum = i.noReceiveNum || 0
                            })
                            return item
                        }) || []
                    // 判断是否是第一次渲染或者是超尺码拣货就进行赋值渲染
                    if (this.first || this.isOverScan) {
                        this.newDataList = list
                        this.first = false
                        this.isOverScan = false
                    }
                    this.totalItem = []
                    //  存放条码集合 用来判断是否是最新单据明细中的条码
                    for (let i = 0; i < data?.goodsInfoList?.length; i++) {
                        // this.totalItem += data.goodsInfoList[i].sizeInfoList.length
                        for (let j = 0; j < data?.goodsInfoList[i]?.sizeInfoList.length; j++) {
                            this.totalItem.push(data?.goodsInfoList[i]?.sizeInfoList[j].barCode)
                        }
                    }
                    this.updataList = list
                    this.sizeGroup = data.sizeGroup
                    this.cardObj = data || {}
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                })
        },
        searchCode(ev) {
            this.$nextTick(() => {
                this.$refs.searchCode.blur()
            })
            if (!this.barCode) {
                this.$nextTick(() => {
                    this.$refs.searchCode.focus()
                })
                return
            }
            //条码不存在则视为超拣货
            if (!this.totalItem.includes(this.barCode)) {
                // 里面写超拣货逻辑
                this.$store.commit('playErrorAudio')
                this.$confirm(`本单不需要拣该货品，确定继续拣货？`, `条码:${this.barCode}`, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'

                }).then(() => {
                    this.isOverScan = true
                    this.searchInNo = this.barCode
                    this.getCheckGoods()
                }).catch(() => {
                    this.barCode=''
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                    return false
                });
                // return false
            } else {
                this.getCheckGoods()
                this.searchInNo = this.barCode
            }
        },
        updateCheckNumber(row, key) {
            let data = {
                typeCode: this.cardObj.typeCode,
                barCode: row.barCode,
                invoicesId: this.cardObj.invoicesId,
                scanType: row.checkHistoryNumber - row[key] > 0 ? '-1' : '1',
                scanNumber: Math.abs(row.checkHistoryNumber - row[key])
            }
            this.getCheckGoods(data)
        },
        getCheckGoods(params) {
            let reqData = params || {
                typeCode: this.cardObj.typeCode,
                barCode: this.barCode,
                invoicesId: this.cardObj.invoicesId,
                scanType: this.scanFlag ? '-1' : '1',
                scanNumber: this.scanNum
            }
            //根据扫描的条码来定位需要更新的行
            for (let i = 0; i < this.updataList.length; i++) {
                for (let j = 0; j < this.updataList[i].sizeInfoList.length; j++) {
                    if (this.barCode === this.updataList[i].sizeInfoList[j].barCode) {
                        // this.updataIdx = i + ''
                        this.updataIdx = this.updataList[i].spuInfoId + this.updataList[i].colorValue
                        break
                    }
                }
            }
            this.loading=true
            erp2OperatingPick(reqData)
                .then((data) => {
                    if (!params) {
                        data.scanNum = !this.scanFlag ? this.scanNum : this.scanNum * -1
                        this.dataOptions.unshift(data)
                    }

                    this.refetchBtn=true
                    // this.$emit('refetch')
                    if (this.snType == '3') {
                        this.dddBfetchDetails()
                    } else {
                        this.fetchDetails()
                    }
                    this.barCode = ''
                    this.loading=false
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                    let messageAudio = new Audio(require('@/assets/audio/scan_tip.mp3'))
                    messageAudio.play()
                })
                .catch((err) => {
                    this.loading=false
                    this.barCode = ''
                    this.$store.commit('playErrorAudio', err)
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                    if (params) {
                        this.$refs.sizeGroupTableIns.restoreCheckValue()
                    }
                })
        },
        close() {
            this.drawerVisiable = false
            this.$emit('close', this.refetchBtn)
            this.$emit('refresh', this.refetchBtn)
        },
        checkNumberChange(row, _row, _value, _oldValue, _key) {
            this.updateCheckNumber(
                {
                    checkHistoryNumber: _oldValue,
                    ..._row
                },
                _key
            )
        }
    }
}
</script>
<style lang="scss" scoped>
.check-accept-drawer {
    .text-link {
        color: #1890ff;
        cursor: pointer;
    }
    .drawer-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .drawer-top-left {
            display: flex;
            justify-content: space-between;
            .label {
                margin-right: 20px;
                display: flex;
                align-items: center;
                color: #333;
            }
            .title-item {
                margin-right: 20px;
            }
            .title-label {
                color: #b0afaf;
                font-size: 16px;
            }
            .title-value {
                font-size: 18px;
                font-weight: 700;
            }
        }
        .sub-box {
            color: black;
            margin: 10px 15px;
            padding: 10px 50px;
            background: #c6e7ff;
            display: flex;
            justify-content: center;
            align-content: center;
            cursor: pointer;
            border-radius: 3px;
            img {
                width: 20px;
                margin-right: 10px;
            }
        }
    }
    .search-row {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: 20px 20px 0;
    }
}
::v-deep .el-drawer__header {
    padding: 20px;
    margin-bottom: 0;
}
::v-deep .el-drawer__body {
    border-top: 5px solid #f5f5f5;
}
.search-line {
    width: 1px;
    height: 15px;
    background: #999;
    margin: 0 10px;
}
.search-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 50px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        .search-btn {
            width: 49px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #409eff;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }
        input {
            border: none;
            font-size: 20px;
            width: 286px;
            background: none;
            outline: none;
            color: #067cf2;
            border: none;
        }
        input::-webkit-input-placeholder {
            color: #067cf2;
        }
        input:focus {
            color: #c56209;
        }
        input:focus::-webkit-input-placeholder {
            color: #c56209;
        }
    }
}
.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
</style>
