<template>
    <!-- 退仓单-出 -->
    <section class="dept-retreat">
        <div class="grid-box">
            <div class="right-box">
                <div>
                    <basic-button
                        :disabled="displayRule('print')"
                        @click="handleCommand('print')"
                        type="primary"
                        :loading="loading"
                        icon-class="print"
                    >
                        打印单据
                    </basic-button>
                    <basic-button
                        :disabled="displayRule('startGet')"
                        @click="handleCommand('startGet')"
                        type="primary"
                        :loading="loading"
                        icon-class="picking"
                    >
                        开始拣货
                    </basic-button>
                    <basic-button
                        :disabled="displayRule('checkGet')"
                        @click="handleCommand('checkGet')"
                        type="primary"
                        :loading="loading"
                        icon-class="expressage"
                    >
                        录入快递信息
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="submit"
                        :disabled="displayRule('submit')"
                        @click="handleCommand('submit')"
                    >
                        提交
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="Dispatched"
                        :disabled="displayRule('copy')"
                        @click="handleCommand('copy')"
                    >
                        复制单据
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="recover"
                        :disabled="displayRule('repick')"
                        @click="handleCommand('repick')"
                    >
                      回退拣货
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="recover"
                        :disabled="displayRule('reorder')"
                        @click="handleCommand('reorder')"
                    >
                        重新做单
                    </basic-button>
                </div>
                <basic-button @click="addNew()" v-if="addFlag == 'Y'" type="primary" :loading="loading" icon-class="add">
                    新增店铺退货单
                </basic-button>
            </div>
            <grid-manager :option="gridOption"></grid-manager>
        </div>
        <SearchFormItem
            :options="options"
            @searchHandel="sureSharch"
            class="search-form-r"
            ref="SearchFormItemInsTwo"
            @getState="getFormState"
        ></SearchFormItem>
        <entryCourierInfo v-if="entryCourierDialog" :modalParams="modalParams" @close="entryCourier" type="TC">
        </entryCourierInfo>

        <InvoicePrint
            v-if="printDialog"
            :printTemplate="deptReturnCode"
            :defaultCopies="deptReturnCount"
            :modalParams="modalParams"
            @close="printDialog = false">
        </InvoicePrint>

        <ShopDetails
            v-if="detailDrawer"
            :modalParams="modalParams"
            type="2"
            @close="detailDrawer = false"
        ></ShopDetails>
        <CheckAccept
            v-if="checkAcceptDrawer"
            :modalParams="modalParams"
            @close="closeDrawer"
            @refetch="sureSharch"
            :type="'2'"
        ></CheckAccept>
        <ReturnDialogModule
            v-if="returnDialogVisiable"
            @close="closeDialog"
            :modalParams="modalParams"
        ></ReturnDialogModule>
        <multiWindow
            @close="closeDialog"
            ref="multiWindowRef"
            :moduleList="moduleList"
            :hasAdd="hasAdd"
            @hasAddChange="hasAddChange"
        ></multiWindow>
    </section>

</template>

<script>
import DialogModule from './components/deptRetreatAddDialog.vue'
import ReturnDialogModule from '../components/returnDialogModule.vue'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import {
    erp2MarketReturnQueryList,
    erp2MarketReturnBatchSubmit,
    erp2MarketReturnQueryInsertPermission,
    erp2OperatingDuplicateInvoices,
    erp2OperatingRestartPick, erp2OperatingResetInvoices
} from '@/libs/http/modules/erp.js'
import entryCourierInfo from '../components/entryCourierInfo.vue'
import ShopDetails from './components/deptRetreatDetails.vue'
import multiWindow from '@/components/multiWindow/index.vue'
import InvoicePrint from '@/components/printInvoices/index'
import CheckAccept from './components/CheckAcceptDrawer.vue' //拣货
import { columnData, options } from './components/config'
import { dialogConfig } from '@/views/invoicesCenter/output/dialogConfig'
export default {
    name: 'deptRetreat',
    components: {
        DialogModule,
        ReturnDialogModule,
        SearchFormItem,
        entryCourierInfo,
        ShopDetails,
        CheckAccept,
        multiWindow,
        InvoicePrint
    },
    data() {
        return {
            gridOption: {
                width: '80vw - 345px',
                height: '85.5vh',
                gridManagerName: 'deptRetreat',
                firstLoading: true,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useTrFocus: true,
                supportPrint: false,
                lineHeight: '20px',
                checkboxConfig: {
                    fixed: 'left',
                    useRowCheck: true
                },
                columnData,
                ajaxData: this.fetch,
				ajaxError: this.ajaxError,
                supportAjaxPage: true,
                cellDblClick: this.rowdbClick,
                checkedAfter: this.checkedAfter,
                dataKey: 'items',
                totalsKey: 'totalItem'
            },
            options,
            entryCourierDialog: false,
            printDialog: false,
            detailDrawer: false,
            checkAcceptDrawer: false,
            loading: false,
            returnDialogVisiable: false,
            modalParams: null,
            checkedList: [],
            addFlag: '',
            hasAdd: 'N',
            moduleList:[],
            dialogConfig,
            deptReturnCode: '',
            deptReturnCount: ''
        }
    },
    created() {
        this.addDialoging()
        this.deptReturnCode = this.$cache.local.get('deptReturnCode');
        this.deptReturnCount = this.$cache.local.get('deptReturnCount');
    },
    methods: {
        checkedAfter(a) {
            this.checkedList = a
        },
        displayRule(type) {
            let rule = true
            let list = this.checkedList
            if (list && list.length) {
                switch (type) {
                    case 'print':
                        rule = !(list.length > 0 && list.every((i) => ![0, 1].includes(+i.state)))
                        break
                    case 'startGet':
                        rule = !(list.length === 1 && list.every((i) => [4, 5].includes(+i.state)))
                        break
                    case 'checkGet':
                        rule = !(list.length === 1 && list.every((i) => [6].includes(+i.state)))
                        break
                    case 'submit':
                        rule = !list.every((i) => ['1'].includes(i.state))
                        break
                    case 'copy':
                        rule = !(list.length === 1 &&list.every((i) => ![].includes(i.state)))
                        break
                    case 'repick':
                        if (list.every((i) => ['6'].includes(i.state)) && list.length == 1) {
                            rule = !rule
                        }
                        break
                    case 'reorder':
                        if (list.every((i)=>i.channel=='50'&&['6','5'].includes(i.state)) && list.length == 1) {
                            rule = !rule
                        }
                    default:
                        break
                }
            }
            return rule
        },

        rowdbClick(row, rowIndex, colIndex) {
            if (colIndex == 1 || colIndex == 0) return
            if (row.state == 1) {
                this.addNew(row)
            } else {
                this.showDetails(row)
            }
        },
        showDetails(row) {
            this.modalParams = row
            this.detailDrawer = true
        },
        reorderHader() {
            const list = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.$confirm('此单是否重新做单', '提示')
                .then(() => {
                    erp2OperatingResetInvoices({
                        typeCode: list[0].typeCode,
                        idList: [list[0].id]
                    })
                        .then((data) => {
                            this.$message.success('操作成功')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        repickHandel() {
            const list = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.$confirm('此单确定回到“拣货中”状态继续拣货?', '提示')
                .then(() => {
                    erp2OperatingRestartPick({
                        typeCode: list[0].typeCode,
                        invoicesId: list[0].id
                    })
                        .then((data) => {
                            this.$message.success('操作成功')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        getFormState(e) {
            GridManager.resetLayout(this.gridOption.gridManagerName, e ? '80vw - 345px' : '80vw - 40px', '83vh')
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        sureSharch(e) {
            this.queryKey = e
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        refetch() {
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        fetch(params) {
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize
            }
            GridManager.setCheckedData(this.gridOption.gridManagerName, [])
            this.checkedList = []
            for (let key in this.queryKey) {
                if (!['updateTime', 'createTime', 'snList'].includes(key)) {
                    reqData[key] = this.queryKey[key]
                }
                if (this.queryKey.createTime && this.queryKey.createTime.length) {
                    let [srart, end] = this.queryKey.createTime
                    reqData['createStartTime'] = `${srart} 00:00:00`
                    reqData['createEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.updateTime && this.queryKey.updateTime.length) {
                    let [srart, end] = this.queryKey.updateTime
                    reqData['updateStartTime'] = `${srart} 00:00:00`
                    reqData['updateEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.snList) {
                    reqData['snList'] = this.queryKey.snList.split(' ')
                }
            }
            this.$parent.quantiting()
            return erp2MarketReturnQueryList(reqData)
        },
		ajaxError(e){
			this.$message({type: 'error', message: e, duration: 2000, showClose: true})
		},
        handleCommand(e) {
            let row = this.checkedList[0]
            switch (e) {
                case 'print':
                    this.printDialog = true
                    this.modalParams = this.checkedList
                    break
                case 'checkGet':
                    this.entryCourierDialog = true
                    this.modalParams = { ...row, invoicesId: row.id, typeCode: row.typeCode || '100020' }
                    break
                case 'startGet':
                    this.checkAcceptDrawer = true
                    this.modalParams = row
                    break
                case 'submit':
                    this.submitList()
                    break
                case 'copy':
                    this.copy()
                    break
                case 'repick':
                    this.repickHandel()
                    break
                case 'reorder':
                    this.reorderHader()
            }
        },
        //作废
        submitList() {
            this.$confirm('是否标记为已下发', '提示')
                .then(() => {
                    erp2MarketReturnBatchSubmit({
                        ids: this.checkedList.map((i) => i.id) || []
                    })
                        .then((data) => {
                            this.$messageDialog(data,'标记为已下发')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        copy() {
            this.$confirm('是否复制此单据', '提示')
                .then(() => {
                    erp2OperatingDuplicateInvoices({
                        type:"ErpMarketReturn",
                        id: this.checkedList[0].id
                        })
                        .then((data) => {
                            this.$messageDialog(data,'复制')
                            // this.$message.success('复制成功')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        addNew(row) {
            let arrId = this.moduleList.map((item) => {
                return item.id
            })
            //编辑
            if (row && !arrId.includes(row?.id)) {
                // row.id = row.invoicesId
                this.moduleList.push(this.dialogConfig.RetreactEditDialog(row))
            }
            //如果已经打开就把最小化的恢复到正常
            if (arrId.includes(row?.id)) {
                for (let ele of this.moduleList) {
                    if (ele.id == row.id) {
                        // this.unfoldTag(ele)
                        this.$refs['multiWindowRef'].unfoldTag(ele)
                    }
                }
            }
            //如果新增已经打开就把最小化的新增恢复正常大小
            if (!row && this.hasAdd == 'Y') {
                for (let ele of this.moduleList) {
                    if (ele.id == '-1') {
                        // this.unfoldTag(ele)
                        this.$refs['multiWindowRef'].unfoldTag(ele)
                    }
                }
            }
            //新增
            if (!row && this.hasAdd == 'N') {
                this.moduleList.push(this.dialogConfig.RetreactAddDialog())
                this.hasAdd = 'Y'
            }

        },
        addDialoging() {
            erp2MarketReturnQueryInsertPermission().then((res) => {
                this.addFlag = res
            })
        },
        closeDialog(e) {
            this.moduleList.forEach((item) => {
                if (item.id == e[1]?.id) {
                    item.isminimize = true
                }
                if (!e[1] && item.id == '-1') {
                    item.isminimize = true
                }
            })
            if (e[0] && e[0] !== 'close') {
                this.options.searchItem[2].value = e[0]
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
            if (e[0] === 'close' || e[0]) {
                this.$refs['multiWindowRef'].closeTag(e[1])
            }
        },
        closeDrawer(refresh) {
            this.checkAcceptDrawer = false
            if (refresh) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        entryCourier(refetch) {
            this.entryCourierDialog = false
            if (refetch) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        hasAddChange(e) {
            this.hasAdd = e
        }
    }
}
</script>

<style lang="scss">
.dept-retreat {
    display: flex;
    padding: 10px;
    .right-box {
        display: flex;
        justify-content: space-between;
    }
}
</style>
