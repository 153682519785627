<template>
  <!-- 调拨单 -->
  <ZDialog
    title="调拨单"
    width="90%"
    ref="zdialog"
    zDialogRef="z-dialog1"
    :show.sync="dialogVisible"
    :is-confirm-close="true"
    :isFooter="false"
    :modal="false"
    @close="close"
    @minimizeVal="minimizeVal">
    <section slot="body" class="content">
      <div class="content-form">
        <div class="content-title">请填写信息</div>
        <el-form ref="form" size="small" :model="form" :rules="rules">
          <el-form-item label="收货方" prop="receiverInfoId">
            <erp-selector
                type="store"
                v-model="form.receiverInfoId"
                :multiple="false"
                placeholder="请选择收货方"
                ref="selector"
                @change='receiverStore'
            ></erp-selector>
          </el-form-item>

          <el-form-item label="备注">
            <el-input
                placeholder="请填写备注信息"
                type="textarea"
                v-model="form.description"
                rows="10"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="content-add">
        <div class="content-title">
                  <span
                      style="
                          width: 4px;
                          height: 18px;
                          background: #067cf2;
                          border-radius: 2px;
                          margin-right: 4px;
                          margin-top: 4px;
                      "
                  ></span>
          新增货品明细
        </div>
        <section class="addTable">
          <el-row :gutter="20" class="add-table-top">
            <el-col :span="16"
            >
            <!-- <erp-selector
                type="goodsNo"
                v-model="form.goodsNo"
                modelKey="goodsNo"
                placeholder="请选择货号"
                @change="searchList"
            ></erp-selector
            > -->
            <erp-selector
                                type="goodsNo"
                                v-model="form.goodsNo"
                                modelKey="goodsNo"
                                :slectShow="false"
                                :inputShow="true"
                                placeholder="请输入货号，多个用空格隔开"
                                @change="searchList"
                            ></erp-selector>
        </el-col>
            <el-col :span="8">
              <el-button size="small" @click="searchList">查 询</el-button>
            </el-col>
          </el-row>
          <grid-manager :option="gridOption"></grid-manager>
          <section
              style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          border: 1px solid #eee;
                          border-top: none;
                          height: 6vh;
                          padding: 0 10px;
                      "
          >
            <div>
              <el-checkbox v-model="stridePage" :disabled="checkboxFalg" @change="selectAll"
              >跨页全选</el-checkbox
              >
            </div>
            <div class="btnTop">
                 <!-- <el-button  @click="addList()" :loading="loading"><svg-icon icon-class="submit"
                   style="font-size: 18px; margin-right: 5px" />确认添加</el-button> -->
                   <basic-button :loading="loading" @click="addList()" type="primary" icon-class="submit">
                                    确认添加
                                </basic-button>
            </div>

          </section>
        </section>
      </div>
      <div class="content-show" style="width: calc(100% - 750px)">
        <div class="content-title">已添加货品列表</div>
        <section>
          <el-form inline size="small" @submit.native.prevent>
              <el-form-item><div class="search-card">
                  <div class="search">
                      <i
                          class="iconfont icon-saoma"
                          style="font-size: 24px"
                          :style="{ color: isInput ? '#C56209' : '#067CF2' }"
                      ></i>
                      <div
                          class="search-line"
                          :style="{ background: isInput ? '#C56209' : '#067CF2' }"
                      ></div>
                      <input
                          type="text"
                          ref="searchCode"
                          placeholder="请扫码或输入条码"
                          @focus="isInput = true"
                          @blur="isInput = false"
                          v-focusInput="1"
                          @keyup.enter="searchCode($event)"
                          v-model="barCode"
                      />
                      <div class="search-btn" @click="searchCode()">确定</div>
                  </div>
              </div></el-form-item>
              <el-form-item>
                  <el-switch
                      size="small"
                      v-model="scanFlag"
                      inactive-color="#44DB5E"
                      active-color="#ff0000"
                      active-text="负扫"
                      inactive-text="正扫"
                      style="margin:0 10px"
                  ></el-switch>
                  <el-input-number size="small" :min="1" :precision="0" v-model="scanNum" style="margin:0 20px"></el-input-number>

              </el-form-item>
              <el-form-item label="">
                  <el-input placeholder="请输入货号" v-model="form.joinGoods" clearable @clear='searchJoin'></el-input>
              </el-form-item>
              <el-form-item>
                  <div style="display: flex">
                      <el-button native-type="submit" @click="searchJoin">查询</el-button>
<!--                      <div class="clearAll-box" @click="clearAll">-->
<!--                          <svg-icon class-name="size-icon" icon-class="clear" style="font-size: 16px;" /> 清空-->
<!--                      </div>-->
                      <basic-button @click="clearAll" type="primary" :loading="loading" icon-class="clear">
                          清空
                      </basic-button>
                      <basic-button @click="clickScantraing" type="primary" >
                          查看扫码轨迹
                      </basic-button>
                  </div>
              </el-form-item>
          </el-form>
            <div  style="margin-bottom: 10px">
                <el-table ref="dataOptions" :data="[dataOptions[0]]" tooltip-effect="dark" class="tablues" border
                          :header-cell-style="{
                        background: '#e8e8e8',
                        padding: '5px 0',
                        color: '#333',
                        fontSize: '14px',
                        height: '40px'
                    }" :cell-style="{
                        padding: '0px 0',
                        fontSize: '14px',
                        height: '30px'
                    }">
                    <el-table-column prop="goodsName" label="当前扫码货品" align="center"> </el-table-column>
                    <el-table-column prop="goodsNo" label="货号" align="center"> </el-table-column>
                    <el-table-column prop="color" label="颜色" align="center"> </el-table-column>
                    <el-table-column prop="size" label="尺码" align="center"></el-table-column>
                    <el-table-column prop="newNum" label="数量" align="center"></el-table-column>
                </el-table>

            </div>
          <SizeGroupTable
              ref="sizeGroupTableIns"
              :dataList="newAddedList"
              :columns="newAddColumns"
              :actions="addActions"
              :filterText="filterText"
              :sizeInfoLabels="newAddSizeInfoLabel"
              :footerOptions="newFooterOptions"
              border
              @actionClick="actionClick"
              @numberChange="numberChange"
          >
            <template v-slot="{ row, item }">
              <el-input
                  v-if="item.prop === 'calloutType'"
                  v-model="row[item.prop]"
                  placeholder="请输入内容"
              ></el-input>
            </template>
          </SizeGroupTable>
            <ScanTrajectory
                v-if="ScanTraDialog"
                :detailsList="newDataOption"
                @close="ScanTraclose"
            >
            </ScanTrajectory>
        </section>
      </div>
    </section>
    <section slot="titleRight" class="dialog-top">
      <div class="btnTop">
          <basic-button
              @click="beforePublish('1')"
              @dblclick.native="onButtonDblClick"
              type="primary"
              :loading="loading"
              icon-class="draft"
          >
              存入草稿
          </basic-button>
          <basic-button
              @dblclick.native="onButtonDblClick"
              @click="beforePublish('4')"
              type="primary"
              :loading="loading"
              icon-class="submit"
          >
              确认提交
          </basic-button>
      </div>
    </section>
  </ZDialog>
</template>

<script>
import ZDialog from "@/components/ZDialog";
import svgIcon from '@/components/SvgIcon/index'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import erpSelector from '@/components/ErpSelector/index'
import ScanTrajectory from '@/views/invoicesCenter/components/ScanTrajectory'
import { addColumnObj, addActions, addSizeInfoLabel,footerObj } from './tableConfig.js'
import {
    erp2MarketAllotSubmitMarketAllot,
    erp2AllotQueryMarketAllotByUpdate,
    erp2marketAllotClientNegativeStockAuthority,
    erp2MarketAllotQueryUnitGoodsInfoList,
    erp2MarketAllotQueryUnitGoodsSizeInfo

} from '@/libs/http/modules/erp.js'
export default {
    name: 'dialogName',
    props: {
        modalParams: {
            type: Object,
            default: () => {}
        },
        storeType: {
            type: String,
            default: ''
        }
    },
    components: {
        SizeGroupTable,
        erpSelector,
        svgIcon,
        ZDialog,
        ScanTrajectory
    },
    data() {
        return {
            footerObj,
            addColumnObj,
            addActions,
            addSizeInfoLabel,
            filterText: '',
            ScanTraDialog:false,
            newAddedList: [],
            dataOptions:[],
            newDataOption:[],
            trackNumber:'',
            dialogVisible: false,
            infoDetailLoading: false,
            isInput: false,
            barCode:'',
            scanNum: 1,
            scanFlag: false,
            form: {
                goodsNo: '', //查询列表搜索
                joinGoods: '', //已添加列表搜索
                senderInfoId: this.$cache.local.get('marketingUnitId'), //发出方
                senderInStoreFoId: this.$cache.local.get('marketingUnitId'),
                receiverInfoId: '', //接收
                description: '' //描述
                // timeClassify: '', //时间
                // designClassify: '', //款式
                // styleClassify: '' //风格
            },
            rules: {
                receiverInfoId: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            gridOption: {
                gridManagerName: 'ArticleNumberList',
                firstLoading: false,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useNoTotalsMode: false,
                lineHeight: '30px',
                columnData: [
                    { key: 'goodsNo', text: '货号' },
                    { key: 'goodsName', text: '货号名称' },
                    { key: 'usableQuantity', text: '可用库存' }
                ],
                ajaxData: this.fecthList,
                dataKey: 'items',
                totalsKey: 'totalItem',
                rowDblClick: this.rowdbClick,
                supportAjaxPage: true,
                height: '74vh',
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--选中信息-->
                    <div class="toolbar-info checked-info"></div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
            },
            headerLeft: [
                { key: 'goodsName', name: '货品名称', type: 'db' },
                { key: 'goodsNo', name: '货号' },
                { key: 'retailPrice', name: '零售价' },
                { key: 'color', name: '颜色' }
            ],
            checkIdList: [],
            stridePage: false,
            addedList: [],
            loading: false,
            onceMax: 0,
            entrepotList: [],
            warehousetotalItem: 0,
            warehousepage: 1,
            warehouseName: '',
            deptOptions: [],
            categoryListTime: [],
            categoryListStyle: [],
            categoryListType: [],
            isAllowNegativeStocks: '', //是否允许负库存出货
            checkboxFalg:true,

        }
    },
    computed: {
        newAddColumns() {
            //铺货
            if (this.invoiceType == 100011) {
                //店店调拨
                return this.addColumnObj['DDDB']
            } else {
                return this.addColumnObj['OTHER']
            }
        },
         newFooterOptions() {
            return this.footerObj['addDB']
        },
        newAddSizeInfoLabel() {
            return this.addSizeInfoLabel.map((item) => {
                if (item.prop === 'number') {
                    item.label = '调拨数'
                }
                return item
            })
        }
    },
    created() {
        this.dialogVisible = true
        if (this.modalParams?.id) {
            this.fetchDetail()
        }
        this.getIsAllowNegativeStocks()
    },
    mounted() {
        // 绑定enter事件
        this.enterKeyup()
    },
    destroyed() {
        // 销毁enter事件
        this.enterKeyupDestroyed()
    },
    methods: {
      onButtonDblClick(event) {//防止双击时导致弹窗触发缩小扩大的功能
        event.stopPropagation()
      },

        clickScantraing() {
            this.ScanTraDialog=true
        },
        ScanTraclose() {
            this.ScanTraDialog=false
        },
        searchCode() {
            if (!this.barCode) {
                return this.$message({
                    type: 'error',
                    message: '请输入条码',
                    duration: 2000,
                    showClose: true
                })
            }
            if (!this.form.receiverInfoId) {
                this.barCode=''
                return this.$message({
                    type: 'error',
                    message: '请选择收货方',
                    duration: 2000,
                    showClose: true
                })
                this.$store.commit('playErrorAudio')
            }
            this.addList(2)
            // this.setCheckGoods()

            this.searchInNo = this.barCode
        },
        receiverStore(e) {
            if(this.form.receiverInfoId==this.form.senderInfoId) {
                this.$message({
                    type: 'error',
                    message: '收货方不能等于发货方',
                    duration: 2000,
                    showClose: true
                })
                this.form.receiverInfoId=''
                this.$refs.selector.options=[]
            }
        },
        getIsAllowNegativeStocks() {
            erp2marketAllotClientNegativeStockAuthority().then((res) => {
                this.isAllowNegativeStocks = res
            })
        },
        rowdbClick(row) {
            this.addList(row)
        },
        enterKey(event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                const checkList = GridManager.getCheckedData(this.gridOption.gridManagerName)
                if (this.stridePage || checkList.length) {
                    this.addList()
                }
            }
        },
        enterKeyupDestroyed() {
            document.removeEventListener('keyup', this.enterKey)
        },
        enterKeyup() {
            document.addEventListener('keyup', this.enterKey)
        },
        close() {
            this.dialogVisible = false
            this.$emit('close',['close',this.modalParams])
        },
        fetchDetail() {
            this.infoDetailLoading = true
            erp2AllotQueryMarketAllotByUpdate({ id: this.modalParams.id })
                .then((data) => {
                    if (data) {
                        this.$refs.selector.options=[{
                            id:data.invoicesInfo.inMarketId,
                            name:data.invoicesInfo.inMarketName,
                            code:data.invoicesInfo.inMarketCode
                        }]
                        this.form.receiverInfoId=data.invoicesInfo.inMarketId
                        if(data.invoicesInfo.remark) {
                            this.form.description=data.invoicesInfo.remark
                        }
                        this.$nextTick(() => {
                            this.addedList = data.goodsInfoList
                            this.newAddedList = this.newAddedList.concat(
                                data.goodsInfoList.map((item) => {
                                        return item
                                })
                            )
                        })
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
                .finally(() => {
                    this.infoDetailLoading = false
                })
        },
        searchList() {
            GridManager.refreshGrid(this.gridOption.gridManagerName,true)
        },
        fecthList(params) {
            let reqData = {
                page: params.pageData.cPage,
                    pageSize: params.pageData.pSize,
                    isAllSelect: 'N',
                    marketAllotId: this.form.senderInfoId,
            }
            // if (this.form.goodsNo.length) {
            //         reqData['spuList'] = this.form.goodsNo
            //     }
            if (this.form.goodsNo.trim().split(/[ ]+/)[0]!='') {
                reqData['spuList'] = this.form.goodsNo.trim().split(/[ ]+/)
            }
                this.checkboxFalg = false
            return erp2MarketAllotQueryUnitGoodsInfoList(reqData)
        },

        searchJoin() {
            //   this.$refs.puhuotab.queryList(this.form.joinGoods);
            this.filterText = this.form.joinGoods
        },
        selectAll(e) {
            if (e) {
                this.fecthAll()
            } else {
                this.checkIdList = []
                GridManager.setCheckedData(this.gridOption.gridManagerName,[])
            }
        },
        fecthAll() {
            GridManager.showLoading(this.gridOption.gridManagerName) //开启加载
            let reqData={
                isAllSelect: 'Y',
                marketAllotId: this.form.senderInfoId,
            }
            // if (this.form.goodsNo.length) {
            //         reqData['spuList'] = this.form.goodsNo
            //     }
            if (this.form.goodsNo.trim().split(/[ ]+/)[0]!='') {
                    reqData['spuList'] = this.form.goodsNo.trim().split(/[ ]+/)
                }
                this.checkboxFalg = true
            erp2MarketAllotQueryUnitGoodsInfoList(reqData)
                .then((data) => {
                    if (data && data.items) {
                this.checkboxFalg = false
                        this.checkIdList = data.items
                        GridManager.setCheckedData(this.gridOption.gridManagerName, this.checkIdList)
                        GridManager.hideLoading(this.gridOption.gridManagerName) //隐藏加载
                    }
                })
                .catch((error) => {
                this.checkboxFalg = false
                    GridManager.hideLoading(this.gridOption.gridManagerName) //隐藏加载
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        getCheckOther() {
            const checkList = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.checkIdList = checkList.map((item) => {
                return item.spuInfoId
            })
        },
        // 去重
        // uniqueFunc(arr, uniId) {
        //     const res = new Map()
        //     return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        // },
        uniqueFunc(arr, uniId1, uniId2) {
            const res = new Map();
            return arr.filter((item) => {
                const key = item[uniId1] + '-' + item[uniId2];
                if (!res.has(key)) {
                    res.set(key, 1);
                    return true;
                }
                return false;
            });
        },
        addList(row) {
            if(!row) {
                this.getCheckOther()
                if (!this.checkIdList.length) {
                    return this.$message.error('请选择货品再添加')
                }
            }
            this.loading = true
            let reqData={
                marketId: this.form.senderInfoId,
                spuIdList: row && row.spuInfoId ? [row.spuInfoId] : this.checkIdList
            }
            if(row==2) {
                reqData={
                    marketId: this.form.senderInfoId,
                    barCode: this.barCode
                }
            }
            erp2MarketAllotQueryUnitGoodsSizeInfo(reqData)
                .then((data) => {
                    this.addedList = data.goodsInfoRespList
                    this.newAddedList = this.newAddedList.concat(
                        data.goodsInfoRespList.map(item => {
                            if (this.invoiceType == 100011) {
                                return {
                                    ...item,
                                    calloutType: item.calloutType || ''
                                }
                            } else {
                                return item
                            }
                        })
                    )
                    if(row==2){
                           let scanTip = new Audio(require('@/assets/audio/scan_tip.mp3'))
                            scanTip.play()
                        if (this.newAddedList.length) {
                            const matchingItem = this.newAddedList.find(item => item.goodsNo === data.goodsInfoRespList[0].goodsNo);
                            if (matchingItem) {
                                matchingItem.sizeInfoList.forEach(item => {
                                    if(item.barCode==this.barCode) {
                                                            if(!this.scanFlag) {
                                                                item.number= Number(item.number)+this.scanNum*1
                                                                this.trackNumber=item.number
                                                            }
                                                            if(this.scanFlag) {
                                                                if(Number(item.number)-this.scanNum*1>=0) {
                                                                    item.number= Number(item.number)-this.scanNum*1
                                                                    this.trackNumber=item.number
                                                                }else {
                                                                    item.number=0
                                                                    this.trackNumber=item.number
                                                                }
                                                            }
                                    }
                                });

                                this.dataOptions = [matchingItem];
                                this.dataOptions.forEach(item => {
                                    item.newNum = this.trackNumber;
                                    item.thisBarCode = data.thisBarCode;
                                    item.size = data.thisSize;
                                    item.color = item.colorValue;
                                });

                                this.newDataOption.unshift({
                                    newNum: this.trackNumber,
                                    thisBarCode: data.thisBarCode,
                                    size: data.thisSize,
                                    color: this.dataOptions[0].color,
                                    goodsNo: this.dataOptions[0].goodsNo,
                                    goodsName: this.dataOptions[0].goodsName,
                                    scanNum: !this.scanFlag ? this.scanNum : this.scanNum*-1


                                });

                            }
                        }
                    }
                    this.newAddedList  =  this.uniqueFunc(this.newAddedList,'goodsNo','colorValue')
                    this.barCode=''
                    this.loading = false
                    this.stridePage = false
                    this.checkIdList = []
                    GridManager.setCheckedData(this.gridOption.gridManagerName, [])
                })
                .catch((error) => {
                    this.barCode=''
                    this.loading = false
                    this.$store.commit('playErrorAudio')
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        clearAll() {
            this.addedList = []
            //   this.$refs.puhuotab.clearAll();
            this.newAddedList = []
        },
        beforePublish(type) {
            if (!this.form.receiverInfoId) {
                return this.$message.error('请选择收货方')
            }
            //   let goodsInfo = this.$refs.puhuotab.getsendData();
            let goodsInfo = this.$refs.sizeGroupTableIns.getsendData(true)
            if (!goodsInfo.length&&type==4) {
                return this.$message.error('请填写调拨数')
            }
            if(!this.form.description) {
                return this.$message.error('请填写备注')
            }
            let goodsStoreList = goodsInfo.map((item) => {
                return {
                    spu: item.spu,
                    sku: item.sku,
                    number: item.number
                }
            })
            let reqData = {
                state: type,
                goodsInfo: goodsStoreList,
                outMarketId: this.form.senderInStoreFoId,
                inMarketId: this.form.receiverInfoId,
            }
            if(this.modalParams?.id) {
                reqData['id']=this.modalParams.id
            }
            if (this.form.description) {
                reqData['remark'] = this.form.description
            }
            if (this.modalParams && this.modalParams.invoicesId) {
                reqData['invoicesId'] = this.modalParams.invoicesId
            }
            this.loading = true;
            this.publishType(reqData)
        },
        publishType(reqData) {
            erp2MarketAllotSubmitMarketAllot(reqData)
                .then((data) => {
                    // this.dialogVisible = false
                    this.$emit('close', [reqData.state,this.modalParams])
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                    this.loading = false;
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                    this.loading = false;
                })
        },
        recursiveFilter(arr) {
            const data = arr
            data.forEach((item) => {
                if (item.pojoList && item.pojoList.length) {
                    this.recursiveFilter(item.pojoList)
                } else {
                    delete item.pojoList
                }
            })
            return data
        },
        actionClick(event, row, rIndex) {
            if (event === 'del') {
                this.$confirm('是否删除该条记录', '提示')
                    .then(() => {
                        this.newAddedList = this.newAddedList.filter((item, index) => item.soleLabel !== row.soleLabel)
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        })
                    })
                    .catch(() => {})
            }
        },
        numberChange(row, _row, _value, _key) {
            if(_row.number*1>1000*1000) {
                this.$set(_row, _key, 0)
                this.$store.commit('playErrorAudio')
                return this.$message.error('数量超出范围')
            }

            if (this.isAllowNegativeStocks === 'N') {
                if (_value > _row.usableQuantity) {
                    this.$set(_row, _key, 0)
                    this.$store.commit('playErrorAudio')
                    return this.$message.error('当前货品不允许负库存啦')
                }
            }
        },
      minimizeVal(e) {
        //是否是最小化，是的话就传给父组件
        if (e) {
          this.$emit('close', ['', this.modalParams])
        }
      },
      isMinimize() {
        this.$refs['zdialog'].minimize()
      }
    }
}
</script>

<style lang="scss" scoped>
.addTable .add-table-top {
    margin-bottom: 18px;
}
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 18px;
        color: #333;
        font-weight: bold;
    }

    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }

    .sub-box {
        color: black;
        margin: 10px 15px;
        padding: 10px 50px;
        background: #c6e7ff;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        border-radius: 3px;
        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}
.search-line {
    width: 1px;
    height: 15px;
    background: #999;
    margin: 0 10px;
}
.search-card {
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 50px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        .search-btn {
            width: 49px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #409eff;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }
        input {
            border: none;
            font-size: 20px;
            width: 170px;
            background: none;
            outline: none;
            color: #067cf2;
            border: none;
        }
        input::-webkit-input-placeholder {
            color: #067cf2;
        }
        input:focus {
            color: #c56209;
        }
        input:focus::-webkit-input-placeholder {
            color: #c56209;
        }
    }
}
.content {
    display: flex;
    justify-content: space-between;
    height: 100%;

    .content-title {
        display: flex;
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .content-form {
        width: 250px;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .content-add {
        width: 450px;
    }

    .content-show {
        width: auto;
        margin-left: 20px;
        margin-right: 25px;
        .clearAll-box {
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 20px;
            background: #c6e7ff;
            font-size: 12px;
            cursor: pointer;
            border-radius: 3px;
            img {
                width: 15px;
                margin-right: 7px;
            }
        }
    }
}
.size-icon {
  margin-right: 5px;
  margin-top: 3px;
}
.btnTop {
    display: flex;
    align-items: center;

    .el-button {
        font-size: 12px;
        padding: 0 20px;
        line-height: 36px;
        color: black;
        border-radius: 0;
        border: none;
        background: #c6e7ff;
        // display: flex;
    }

    //图标居中？
    .svg-icon {
        vertical-align: -0.2em;
    }

    .disable-Class {
        // background: #d7d7d7;
        filter: grayscale(75%);
    }

    .el-button:hover {
        background: #9ed2f8;
    }

    .disable-Class:hover {
        // background: #d7d7d7;
        filter: grayscale(75%);
    }
}
</style>
