<template>
    <!-- 退仓 -->

    <!--    <el-drawer-->
    <!--        size="99%"-->
    <!--        :visible.sync="dialogVisible"-->
    <!--        :show-close="false"-->
    <!--        :wrapperClosable="false"-->
    <!--        v-loading="infoDetailLoading"-->
    <!--        :close-on-press-escape="false"-->
    <!--        @close="close"-->
    <!--    >-->
    <ZDialog
        title="退仓单"
        width="90%"
        ref="zdialog"
        zDialogRef="z-dialog1"
        :show.sync="dialogVisible"
        :is-confirm-close="true"
        :isFooter="false"
        :modal="false"
        @close="close"
        @minimizeVal="minimizeVal"
    >
        <section slot="body" class="content">
            <div class="content-form">
                <div class="content-title">请填写信息</div>
                <el-form ref="form" size="small" :model="form" :rules="rules">
                    <el-form-item label="收货方" prop="receiverInfoId">
                        <erp-selector
                            type="warehouse"
                            v-model="form.receiverInfoId"
                            ref="warehouseReceiver"
                            placeholder="请选择仓库"
                            :isRejection='true'
                            :multiple="false"
                        ></erp-selector>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            placeholder="请填写备注信息"
                            type="textarea"
                            v-model="form.description"
                            rows="10"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content-add">
                <div class="content-title">
                    <span
                        style="
                            width: 4px;
                            height: 18px;
                            background: #067cf2;
                            border-radius: 2px;
                            margin-right: 4px;
                            margin-top: 4px;
                        "
                    ></span>
                    新增货品明细
                </div>
                <section class="addTable">
                    <el-row :gutter="20" class="add-table-top">
                        <el-col :span="16"
                            >
<!--                            <erp-selector-->
<!--                                type="goodsNo"-->
<!--                                v-model="form.spuList"-->
<!--                                modelKey="spu"-->
<!--                                placeholder="请选择货号"-->
<!--                                @change="searchList"-->
<!--                            ></erp-selector-->
<!--                        >-->
                            <erp-selector
                                type="goodsNo"
                                v-model="form.spuList"
                                modelKey="goodsNo"
                                :slectShow="false"
                                :inputShow="true"
                                placeholder="请输入货号，多个用空格隔开"
                                @change="searchList"
                            ></erp-selector>
                        </el-col>
                        <el-col :span="8">
                            <el-button size="small" @click="searchList">查 询</el-button>
                        </el-col>
                    </el-row>
                    <grid-manager :option="gridOption"></grid-manager>
                    <section
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border: 1px solid #eee;
                            border-top: none;
                            height: 6vh;
                            width: 450px;
                            padding: 0 10px;
                        "
                    >
                        <div>
                            <el-checkbox v-model="stridePage" @change="selectAll">跨页全选</el-checkbox>
                        </div>
                        <basic-button @click="addList()" type="primary" :loading="loading" icon-class="submit">
                            确认添加
                        </basic-button>
                    </section>
                </section>
            </div>
            <div class="content-show" style="width: calc(100% - 750px)">
                <div class="content-title">已添加货品列表</div>
                <section>
                    <el-form inline size="small" @submit.native.prevent>
                        <el-form-item>
                            <div class="search-card">
                                <div class="search">
                                    <i
                                        class="iconfont icon-saoma"
                                        style="font-size: 24px"
                                        :style="{ color: isInput ? '#C56209' : '#067CF2' }"
                                    ></i>
                                    <div
                                        class="search-line"
                                        :style="{ background: isInput ? '#C56209' : '#067CF2' }"
                                    ></div>
                                    <input
                                        type="text"
                                        ref="searchCode"
                                        placeholder="请扫码或输入条码"
                                        @focus="isInput = true"
                                        @blur="isInput = false"
                                        v-focusInput="1"
                                        @keyup.enter="searchCode($event)"
                                        v-model="barCode"
                                    />
                                    <div class="search-btn" @click="searchCode()">确定</div>
                                </div>
                            </div>
                        </el-form-item>

                        <el-form-item>
                            <el-switch
                                size="small"
                                v-model="scanFlag"
                                inactive-color="#44DB5E"
                                active-color="#ff0000"
                                active-text="负扫"
                                inactive-text="正扫"
                                style="margin:0 10px"
                            ></el-switch>
                            <el-input-number size="small" :min="1" :precision="0" v-model="scanNum" style="margin:0 10px"></el-input-number>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input placeholder="请输入货号" v-model="form.joinGoods" clearable @clear='searchJoin'></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div style="display: flex">
                                <el-button native-type="submit" @click="searchJoin">查询</el-button>

                                <basic-button @click="clearAll" type="primary" :loading="loading" icon-class="clear">
                                    清空
                                </basic-button>
                                <basic-button @click="clickScantraing" type="primary">
                                    查看扫码轨迹
                                </basic-button>
                            </div>
                        </el-form-item>


                    </el-form>
                    <section style='margin-bottom: 10px'>
                        <el-table ref="dataOptions" :data="dataOptions" tooltip-effect="dark" class="tablues" border
                                   :header-cell-style="{
                        background: '#e8e8e8',
                        padding: '5px 0',
                        color: '#333',
                        fontSize: '14px',
                        height: '40px'
                    }" :cell-style="{
                        padding: '0px 0',
                        fontSize: '14px',
                        height: '30px'
                    }">
                            <el-table-column prop="goodsName" label="当前扫码货品" align="center"> </el-table-column>
                            <el-table-column prop="goodsNo" label="货号" align="center"> </el-table-column>
                            <el-table-column prop="color" label="颜色" align="center"> </el-table-column>
                            <el-table-column prop="size" label="尺码" align="center"></el-table-column>
                            <el-table-column prop="newNum" label="数量" align="center"></el-table-column>
                        </el-table>

                    </section>

                    <section>
                        <SizeGroupTable
                            ref="sizeGroupTableIns"
                            :dataList="newAddedList"
                            :columns="addColumn"
                            :actions="addActions"
                            :filterText="filterText"
                            :sizeInfoLabels="newAddSizeInfoLabel"
                             :footerOptions="footerObj"
                            border
                            @actionClick="actionClick"
                            @numberChange="numberChange"
                        >
                            <template v-slot="{ row, item }">
                                <el-input
                                    v-if="item.prop === 'calloutType'"
                                    v-model="row[item.prop]"
                                    placeholder="请输入内容"
                                ></el-input>
                            </template>
                        </SizeGroupTable>
                        <ScanTrajectory
                            v-if="ScanTraDialog"
                            :detailsList="newDataOption"
                            @close="ScanTraDialoging"
                        >
                        </ScanTrajectory>
                    </section>
                </section>
            </div>
        </section>
        <section slot="titleRight" class="dialog-top">
            <div class="btnTop">
                <basic-button @click="beforePublish('1')"   @dblclick.native="onButtonDblClick" type="primary" :loading="loading" icon-class="draft">
                    存入草稿
                </basic-button>
                <basic-button @click="beforePublish('4')"   @dblclick.native="onButtonDblClick" type="primary" :loading="loading" icon-class="submit">
                    确认提交
                </basic-button>
            </div>
        </section>

    </ZDialog>
    <!--        <section slot="title" class="dialog-top">-->
    <!--            <p>新增店铺退货单</p>-->
    <!--            <div style="display: flex">-->
    <!--                <basic-button @click="beforePublish(1)" type="primary" :loading="loading" icon-class="draft">-->
    <!--                    存入草稿-->
    <!--                </basic-button>-->
    <!--                <basic-button @click="beforePublish(4)" type="primary" :loading="loading" icon-class="submit">-->
    <!--                    确认提交-->
    <!--                </basic-button>-->

    <!--                <i class="el-icon-close" @click="dialogVisible = false"></i>-->
    <!--            </div>-->
    <!--        </section>-->

    <!--    </el-drawer>-->
</template>

<script>
import ZDialog from '@/components/ZDialog'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import erpSelector from '@/components/ErpSelector/index'
import ScanTrajectory from '@/views/invoicesCenter/components/ScanTrajectory'
import { addColumn, addActions, addSizeInfoLabel,footerObj } from './deptRetreatAdd.js'

import {
    erp2MarketReturnSubmitMarketReturn,
    erp2MarketReturnQueryMarketReturnByUpdate,
    erp2MarketReturnQueryUnitGoodsSizeInfo,
    erp2MarketReturnQueryUnitGoodsInfoList
} from '@/libs/http/modules/erp.js'

export default {
    name: 'dialogName',
    props: {
        modalParams: {
            type: Object,
            default: () => {}
        },
        storeType: {
            type: String,
            default: ''
        }
    },
    components: {
        SizeGroupTable,
        erpSelector,
        ZDialog,
        ScanTrajectory
    },
    data() {
        return {
            footerObj,
            addColumn,
            addActions,
            addSizeInfoLabel,
            filterText: '',
            isInput: false,
            dataOptions:[],
            ScanTraDialog:false,
            detailsList:[],
            newDataOption:[],//扫码轨迹数组
            trackNumber:'',
            scanNum: 1,
            scanFlag: false,
            barCode:'',
            dialogVisible: false,
            infoDetailLoading: false,
            form: {
                spuList: '', //查询列表搜索
                joinGoods: '', //已添加列表搜索
                receiverInfoId: '', //接收
                description: '' //描述
            },
            rules: {
                // senderInfoId: [{ required: true, message: '请选择', trigger: 'blur' }],
                receiverInfoId: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            gridOption: {
                gridManagerName: 'ArticleNumberList',
                firstLoading: false,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useNoTotalsMode: false,
                lineHeight: '30px',
                columnData: [
                    { key: 'goodsNo', text: '货号' },
                    { key: 'goodsName', text: '货号名称' },
                    { key: 'usableQuantity', text: '可用库存' }
                ],
                ajaxData: this.fecthList,
                dataKey: 'items',
                totalsKey: 'totalItem',
                rowDblClick: this.rowdbClick,
                supportAjaxPage: true,
                height: '74vh',
                width: '450px',
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--选中信息-->
                    <div class="toolbar-info checked-info"></div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
            },
            checkIdList: [],
            stridePage: false,
            newAddedList: [],
            loading: false
        }
    },
    computed: {
        newAddSizeInfoLabel() {
            return this.addSizeInfoLabel
        }
    },
    created() {
        this.dialogVisible = true
        if (this.modalParams?.id) {
            this.fetchDetail()
        }
    },
    // mounted() {
    //     // 绑定enter事件
    //     this.enterKeyup()
    // },
    // destroyed() {
    //     // 销毁enter事件
    //     this.enterKeyupDestroyed()
    // },
    methods: {
      onButtonDblClick(event) {//防止双击时导致弹窗触发缩小扩大的功能
        event.stopPropagation()
      },
        rowdbClick(row) {
            this.addList(row, true)
        },
        enterKey(event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                this.addList()
            }
        },
        ScanTraDialoging() {
            this.ScanTraDialog=false
        },
        // enterKeyupDestroyed() {
        //     document.removeEventListener('keyup', this.enterKey)
        // },
        // enterKeyup() {
        //     document.addEventListener('keyup', this.enterKey)
        // },
        close() {
            this.dialogVisible = false
            // this.$emit('close')
            this.$emit('close', ['close', this.modalParams])
        },
        // 去重
        // uniqueFunc(arr, uniId) {
        //     const res = new Map()
        //     return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        // },
        uniqueFunc(arr, uniId1, uniId2) {
            const res = new Map();
            return arr.filter((item) => {
                const key = item[uniId1] + '-' + item[uniId2];
                if (!res.has(key)) {
                    res.set(key, 1);
                    return true;
                }
                return false;
            });
        },
        clickScantraing() {
          this.ScanTraDialog=true
        },

        searchCode() {
            if (!this.barCode) {
                return this.$message({
                    type: 'error',
                    message: '请输入条码',
                    duration: 2000,
                    showClose: true
                })
            }
            if (!this.form.receiverInfoId) {
                this.barCode=''
                return this.$message({
                    type: 'error',
                    message: '请选择收货方',
                    duration: 2000,
                    showClose: true
                })
                this.$store.commit('playErrorAudio')
            }
            // this.addList(2)

            erp2MarketReturnQueryUnitGoodsSizeInfo({
                warehouseId: this.form.receiverInfoId,
                barCode: this.barCode
            })
                .then((data) => {
                   let scanTip = new Audio(require('@/assets/audio/scan_tip.mp3'))
                    scanTip.play()
                    this.newAddedList = this.newAddedList.concat(data.goodsInfoRespList)
                    this.newAddedList  =  this.uniqueFunc(this.newAddedList,'goodsNo','colorValue')
                    if (this.newAddedList.length) {
                        const matchingItem = this.newAddedList.find(item => item.goodsNo === data.goodsInfoRespList[0].goodsNo);

                        if (matchingItem) {
                            matchingItem.sizeInfoList.forEach(item => {
                                if(item.barCode==this.barCode) {
                                    if(!this.scanFlag) {
                                        item.number= Number(item.number)+this.scanNum*1
                                        this.trackNumber=item.number
                                    }
                                    if(this.scanFlag) {
                                        if(Number(item.number)-this.scanNum*1>=0) {
                                            item.number= Number(item.number)-this.scanNum*1
                                            this.trackNumber=item.number
                                        }else {
                                            item.number=0
                                            this.trackNumber=item.number
                                        }
                                    }
                                }
                            });

                            this.dataOptions = [matchingItem];
                            this.dataOptions.forEach(item => {
                                item.newNum = this.trackNumber;
                                item.thisBarCode = data.thisBarCode;
                                item.size = data.thisSize;
                                item.color = item.colorValue;
                            });

                            this.newDataOption.unshift({
                                newNum: this.trackNumber,
                                thisBarCode: data.thisBarCode,
                                size: data.thisSize,
                                color: this.dataOptions[0].color,
                                goodsNo: this.dataOptions[0].goodsNo,
                                goodsName: this.dataOptions[0].goodsName,
                                scanNum: !this.scanFlag ? this.scanNum : this.scanNum*-1
                            });
                        }
                    }
                    this.loading = false
                    // this.checkIdList = []
                    this.barCode=''
                    GridManager.setCheckedData(this.gridOption.gridManagerName, [])
                })
                .catch((error) => {
                    this.$store.commit('playErrorAudio')
                    this.barCode=''
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
            // this.setCheckGoods()
            this.searchInNo = this.barCode
        },
        fetchDetail() {
            this.infoDetailLoading = true
            erp2MarketReturnQueryMarketReturnByUpdate({ id: this.modalParams.id })
                .then((data) => {
                    if (data) {
                        if (data.invoicesInfo && Object.keys(data.invoicesInfo).length) {
                            this.form.receiverInfoId = data.invoicesInfo.warehouseId //接收
                            this.form.description = data.invoicesInfo.remark //描述
                            //接收方
                            this.$refs.warehouseReceiver.options = [
                                {
                                    id: data.invoicesInfo.warehouseId,
                                    code: data.invoicesInfo.warehouseCode,
                                    name: data.invoicesInfo.warehouseName
                                }
                            ]
                        }
                        this.$nextTick(() => {
                            this.newAddedList = this.newAddedList.concat(data.goodsInfoList)
                        })
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
                .finally(() => {
                    this.infoDetailLoading = false
                })
        },
        searchList() {
            GridManager.refreshGrid(this.gridOption.gridManagerName,true)
        },
        selectAll() {
            if (this.stridePage) {
                this.$message.info('跨页全选数据量较大,请稍等')
                GridManager.showLoading(this.gridOption.gridManagerName)
                let reqData = {
                    isAllSelect: 'Y'
                }
                if (this.form.spuList.trim().split(/[ ]+/)[0]!='') {
                    reqData['spuList'] = this.form.spuList.trim().split(/[ ]+/)
                }
                erp2MarketReturnQueryUnitGoodsInfoList(reqData)
                    .then((data) => {
                        if (data && data.items) {
                            this.checkIdList = data.items.map((i) => i.spuInfoId) || []
                        }
                    })
                    .finally(() => {
                        GridManager.hideLoading(this.gridOption.gridManagerName, 100)
                    })
            }
        },
        fecthList(params) {
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                isAllSelect: 'N',
            }
            if (this.form.spuList.trim().split(/[ ]+/)[0]!='') {
                reqData['spuList'] = this.form.spuList.trim().split(/[ ]+/)
            }
            return erp2MarketReturnQueryUnitGoodsInfoList(reqData)
        },
        searchJoin() {
            this.filterText = this.form.joinGoods
        },
        addList(row) {
            if (!this.stridePage) {
                if (row) {
                    this.checkIdList = row.spuInfoId ? [row.spuInfoId] : []
                } else {
                    this.checkIdList =
                        GridManager.getCheckedData(this.gridOption.gridManagerName).map((i) => i.spuInfoId) || []
                }
            }
            if (!this.checkIdList.length) {
                return this.$message.error('请选择货品再添加')
            }
            this.loading = true
            erp2MarketReturnQueryUnitGoodsSizeInfo({
                warehouseId: this.form.receiverInfoId,
                spuIdList: this.checkIdList
            })
                .then((data) => {
                    this.newAddedList = this.newAddedList.concat(data.goodsInfoRespList)
                    this.newAddedList  =  this.uniqueFunc(this.newAddedList,'goodsNo','colorValue')
                    this.loading = false
                    this.checkIdList = []
                    GridManager.setCheckedData(this.gridOption.gridManagerName, [])
                })
                .catch((error) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        clearAll() {
            this.newAddedList = []
        },

        beforePublish(type) {
            if (!this.form.receiverInfoId) {
                return this.$message.error('请选择收货方')
            }
            let goodsInfo = this.$refs.sizeGroupTableIns.getsendData(true)
            if (!goodsInfo.length&&type==4) {
                return this.$message.error('请填写退仓数')
            }
            if(!this.form.description) {
                return this.$message.error('请填写备注')
            }

            let reqData = {
                state: type,
                goodsInfo: goodsInfo,
                remark: this.form.description,
                // marketId: this.form.senderInfoId,// 后端自取
                warehouseId: this.form.receiverInfoId
            }
            if (this.modalParams) {
                reqData['id'] = this.modalParams.id
            }
            this.publishType(reqData)
        },
        publishType(reqData) {
            this.loading=true
            erp2MarketReturnSubmitMarketReturn(reqData)
                .then((data) => {
                    this.dialogVisible = false
                    this.$emit('close', [[reqData.state], this.modalParams])
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                    this.loading=false
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                    this.loading=false
                })

        },
        recursiveFilter(arr) {
            const data = arr
            data.forEach((item) => {
                if (item.pojoList && item.pojoList.length) {
                    this.recursiveFilter(item.pojoList)
                } else {
                    delete item.pojoList
                }
            })
            return data
        },
        actionClick(event, row, rIndex) {
            if (event === 'del') {
                this.$confirm('是否删除该条记录', '提示')
                    .then(() => {
                        this.newAddedList = this.newAddedList.filter((item, index) => item.soleLabel !== row.soleLabel)
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        })
                    })
                    .catch(() => {})
            }
        },
        numberChange(row, _row, _value, _key) {
            if(_row.number*1>1000*1000) {
            this.$set(_row, _key, 0)
            this.$store.commit('playErrorAudio')
            return this.$message.error('数量超出范围')
        }

        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        minimizeVal(e) {
            //是否是最小化，是的话就传给父组件
            if (e) {
                this.$emit('close', ['', this.modalParams])
            }
        },
        isMinimize() {
            this.$refs['zdialog'].minimize()
        }
    }
}
</script>

<style lang="scss" scoped>
.search-line {
    width: 1px;
    height: 15px;
    background: #999;
    margin: 0 10px;
}
.search-card {
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 50px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        .search-btn {
            width: 49px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #409eff;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }
        input {
            border: none;
            font-size: 20px;
            width: 170px;
            background: none;
            outline: none;
            color: #067cf2;
            border: none;
        }
        input::-webkit-input-placeholder {
            color: #067cf2;
        }
        input:focus {
            color: #c56209;
        }
        input:focus::-webkit-input-placeholder {
            color: #c56209;
        }
    }
}
.addTable .add-table-top {
    margin-bottom: 18px;
}
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 18px;
        color: #333;
        font-weight: bold;
    }
    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }
}
.content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .content-title {
        display: flex;
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content-form {
        width: 250px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .content-add {
        width: 550px;
    }
    .content-show {
        width: auto;
        margin-left: 20px;
        margin-right: 25px;
        .clearAll-box {
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 20px;
            background: #c6e7ff;
            font-size: 12px;
            cursor: pointer;
            border-radius: 3px;
            img {
                width: 15px;
                margin-right: 7px;
            }
        }
    }
}
.sub-box {
    color: black;
    margin: 10px 15px;
    padding: 10px 50px;
    background: #c6e7ff;
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    border-radius: 3px;
    img {
        width: 20px;
        margin-right: 10px;
    }
}
.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
</style>
